export default {
    data() {
        return {
            currencies: {
                HUF: {
                    suffix: true,
                    symbol: 'Ft',
                },
                GBP: {
                    suffix: false,
                    symbol: '£',
                },
                EUR: {
                    suffix: true,
                    symbol: '€',
                },
            },
        }
    },
    methods: {
        getCurrencySymbol(currency) {
            return (
                this.currencies[currency] || {
                    suffix: true,
                    symbol: '⚠',
                }
            )
        },
    },
}
