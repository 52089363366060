<script>
import FormInput from '@/components/mixins/FormInput'

import FormTwoColRow from '@/components/form/FormTwoColRow'
import TextInput from '@/components/form/Inputs/TextInput'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import DatePickerInput from '@/components/form/Inputs/DatePickerInput'

export default {
    components: {
        FormTwoColRow,
        TextInput,
        CheckboxInput,
        DatePickerInput,
    },

    mixins: [FormInput],
}
</script>

<template lang="pug">
    .order-software-sending
        v-row
            v-col(cols="12" md)
               text-input(
                    v-model="model.software"
                    name="software"
                    label="labels.software"
                    :disabled="true")

            v-col(cols="12" md="auto")
                checkbox-input(
                    v-model="model.isSent"
                    label="labels.is_sent"
                    name="isSent"
                    :disabled="true")
            v-col(cols="12" md)
                date-picker-input(
                    v-model="model.sendAt"
                    label="labels.starter_delivery_date"
                    name="starter_delivery_date")

</template>
