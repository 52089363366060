<template>
    <form-panel :title="$t('labels.order_refund_history')">
        <v-simple-table>
            <thead>
                <tr>
                    <th>{{ $t('labels.date') }}</th>
                    <th>{{ $t('labels.amount') }}</th>
                    <th>{{ $t('orders.on_behalf_of') }}</th>
                    <th>{{ $t('orders.item') }}</th>
                    <th>{{ $t('orders.refund_reason') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="refund in order.refunds"
                    :key="refund.id"
                    class="v-align-top"
                >
                    <td class="py-2">
                        {{
                            (refund.createdAt || order.updatedAt)
                                | toDate('YYYY-MM-DD HH:mm')
                        }}
                    </td>
                    <td class="py-2">
                        {{
                            refund.amount
                                | currency(order.currency, order.locale)
                        }}
                    </td>
                    <td class="py-2">
                        {{
                            $t(
                                `enums.refund_on_behalf.${refund.refundOnBehalf}`
                            )
                        }}
                    </td>
                    <td class="py-2">
                        <div
                            v-for="(item, index) in itemNames(refund)"
                            :key="index"
                        >
                            {{ item }}
                        </div>
                    </td>
                    <td class="py-2">
                        <div
                            v-for="(reason, index) in reasons(refund)"
                            :key="index"
                        >
                            {{ reason }}
                        </div>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </form-panel>
</template>

<script>
import FormPanel from '@/components/form/FormPanel'

export default {
    components: { FormPanel },
    props: {
        order: {
            type: Object,
            default: () => ({ meals: [] }),
        },
    },
    methods: {
        itemNames(refund) {
            return refund.itemRefunds.length
                ? refund.itemRefunds.map(
                      (item) => `${item.orderingItem.name} * ${item.quantity}`
                  )
                : ['--']
        },
        reasons(refund) {
            return refund.itemRefunds.length
                ? refund.itemRefunds.map((item) => item.reason)
                : [refund.reason]
        },
    },
}
</script>
