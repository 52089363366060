<template lang="pug">
.edit-delivery
    v-btn.px-0(
        color="primary"
        v-if="deliveryJob.canYouEditAddress || deliveryJob.canYouEditContactInfo || isRestaurantManaged"
        @click="setDialog(true)" text x-small)
        span.text-subtitle-2 {{$t('labels.change')}}
        v-icon(dense) chevron_right
    modal-dialog.edit-delivery-modal(
        v-model="dialog"
        :title="$t('labels.edit_delivery_details')"
        :ok-text="$t('actions.confirm')"
        :show-overlay="loading"
        @cancel="setDialog(false)"
        @ok="onApprove"
        :ok-disabled="!!(!changed || errors.items.length)"
        :ok-loading="loading"
        :scrollable="!isRestaurantManaged"
        cancel-button)
            template(v-if="isRestaurantManaged")
                date-picker-input(
                    v-model="innerModel.requiredDeliveryTime"
                    v-validate.immediate="'required|dateafter'"
                    :min="minDate"
                    label="labels.delivery_time"
                    name="requiredDeliveryTime"
                    :error-messages="errors.collect('requiredDeliveryTime')"
                    :data-vv-as="$t('labels.delivery_time')")
                address-autocomplete-input(
                    v-model="innerModel.deliveryFullAddress"
                    v-validate.immediate="'required|max:255'"
                    label="labels.delivery_full_address"
                    name="deliveryFullAddress"
                    :error-messages="errors.collect('deliveryFullAddress')"
                    :data-vv-as="$t('labels.delivery_full_address')"
                )
                text-input(
                    v-model="innerModel.deliverySubAddress"
                    v-validate="'max:255'"
                    label="labels.delivery_sub_address"
                    name="deliverySubAddress"
                    :error-messages="errors.collect('deliverySubAddress')"
                    :data-vv-as="$t('labels.delivery_sub_address')")
            template(v-else)
                .text-h6.font-weight-bold {{$t('labels.pickup_details')}}
                v-row(v-if="innerDeliveryJob.pickupAddress")
                    v-col(cols="12")
                        date-picker-input(
                            v-model="innerDeliveryJob.pickupTime"
                            v-validate.immediate="'required|dateafter'"
                            label="orders.pickup_time"
                            name="pickupDate"
                            :error-messages="errors.collect('pickupDate')"
                            :data-vv-as="$t('orders.pickup_time')"
                            :min="minDate"
                            :editable="innerDeliveryJob.canYouEditAddress")

                    v-col(cols="12")
                        address-autocomplete-input(
                            v-model="innerDeliveryJob.pickupAddress.full_address"
                            v-validate.immediate="'required|max:255'"
                            :latitude.sync="innerDeliveryJob.pickupAddress.latitude"
                            :longitude.sync="innerDeliveryJob.pickupAddress.longitude"
                            :country.sync="innerDeliveryJob.pickupAddress.country"
                            :town.sync="innerDeliveryJob.pickupAddress.city"
                            :zip-code.sync="innerDeliveryJob.pickupAddress.postal_code"
                            :street.sync="innerDeliveryJob.pickupAddress.street"
                            :house-number.sync="innerDeliveryJob.pickupAddress.street_number"
                            :error-messages="errors.collect('pickupAddress')"
                            label="orders.pickup_address"
                            :disabled="!innerDeliveryJob.canYouEditAddress"
                            name="pickupAddress"
                            :data-vv-as="$t('orders.pickup_address')")
                    v-col(cols="12" md="6")
                        text-input(
                            v-model="innerDeliveryJob.pickupAddress.contact_first_name"
                            v-validate="'required|min:2|max:50|space'"
                            label="orders.first_name"
                            :disabled="!innerDeliveryJob.canYouEditContactInfo"
                            name="pickupFirstName"
                            :error-messages="errors.collect('pickupFirstName')"
                            :data-vv-as="$t('orders.first_name')")
                    v-col(cols="12" md="6")
                        text-input(
                            v-model="innerDeliveryJob.pickupAddress.contact_last_name"
                            v-validate="'required|min:2|max:50|space'"
                            label="orders.last_name"
                            :disabled="!innerDeliveryJob.canYouEditContactInfo"
                            name="pickupLastName"
                            :error-messages="errors.collect('pickupLastName')"
                            :data-vv-as="$t('orders.last_name')")
                    v-col(cols="12" md="6")
                        text-input(
                            v-model="innerDeliveryJob.pickupAddress.contact_phone"
                            v-validate="'required|phonenumber'"
                            label="orders.phone_number"
                            :disabled="!innerDeliveryJob.canYouEditContactInfo"
                            name="pickupPhoneNumber"
                            :error-messages="errors.collect('pickupPhoneNumber')")
                    v-col(cols="12")
                        text-input(
                            v-model="innerDeliveryJob.pickupAddress.contact_company"
                            label="orders.pickup_address"
                            :disabled="!innerDeliveryJob.canYouEditContactInfo"
                            name="pickupCompany")
                    v-col(cols="12")
                        text-input(
                            v-model="innerDeliveryJob.pickupComment"
                            label="orders.pickup_comment"
                            :disabled="!innerDeliveryJob.canYouEditContactInfo"
                            name="pickupComment"
                            counter="9999")

                .text-h6.font-weight-bold {{$t('labels.dropoff_details')}}
                v-row(v-if="innerDeliveryJob.dropoffAddress")
                    v-col(cols="12")
                        date-picker-input(
                            v-model="innerModel.requiredDeliveryTime"
                            v-validate.immediate="'required|dateafter'"
                            :min="minDate"
                            label="labels.delivery_time"
                            name="requiredDeliveryTime"
                            :error-messages="errors.collect('requiredDeliveryTime')"
                            :data-vv-as="$t('labels.delivery_time')")
                        address-autocomplete-input(
                            v-model="innerDeliveryJob.dropoffAddress.full_address"
                            v-validate.immediate="'required|max:255'"
                            :latitude.sync="innerDeliveryJob.dropoffAddress.latitude"
                            :longitude.sync="innerDeliveryJob.dropoffAddress.longitude"
                            :country.sync="innerDeliveryJob.dropoffAddress.country"
                            :town.sync="innerDeliveryJob.dropoffAddress.city"
                            :zip-code.sync="innerDeliveryJob.dropoffAddress.postal_code"
                            :street.sync="innerDeliveryJob.dropoffAddress.street"
                            :house-number.sync="innerDeliveryJob.dropoffAddress.street_number"
                            :error-messages="errors.collect('dropoffAddress')"
                            label="orders.dropoff_address"
                            :disabled="!innerDeliveryJob.canYouEditAddress"
                            name="dropoffAddress"
                            :data-vv-as="$t('orders.dropoff_address')")
                    v-col(cols="12" md="6")
                        text-input(
                            v-model="innerDeliveryJob.dropoffAddress.contact_first_name"
                            v-validate="'required|min:2|max:255|space'"
                            label="orders.first_name"
                            :disabled="!innerDeliveryJob.canYouEditContactInfo"
                            name="pickupFirstName"
                            counter="255"
                            :error-messages="errors.collect('pickupFirstName')"
                            :data-vv-as="$t('orders.first_name')")
                    v-col(cols="12" md="6")
                        text-input(
                            v-model="innerDeliveryJob.dropoffAddress.contact_last_name"
                            v-validate="'required|min:2|max:255|space'"
                            label="orders.last_name"
                            :disabled='!innerDeliveryJob.canYouEditContactInfo'
                            name='pickupLastName'
                            counter="255"
                            :error-messages="errors.collect('pickupLastName')"
                            :data-vv-as="$t('orders.last_name')")
                    v-col(cols="12" md="6")
                        text-input(
                            v-model='innerDeliveryJob.dropoffAddress.contact_phone'
                            v-validate="'required|min:2|max:50|space'"
                            label="orders.phone_number"
                            :disabled="!innerDeliveryJob.canYouEditContactInfo"
                            name="dropoffPhoneNumber"
                            counter="50"
                            :error-messages="errors.collect('dropoffPhoneNumber')"
                            :data-vv-as="$t('orders.phone_number')")
                    v-col(cols="12" md="6")
                        text-input(
                            v-model="innerDeliveryJob.dropoffAddress.contact_company"
                            v-validate="'required|min:2|max:255|space'"
                            label="orders.company"
                            :disabled="!innerDeliveryJob.canYouEditContactInfo"
                            name="dropoffCompany"
                            counter="255"
                            :error-messages="errors.collect('dropoffCompany')"
                            :data-vv-as="$t('orders.company')")
                    v-col(cols="12")
                        text-input(
                            v-model="innerDeliveryJob.dropOffComment"
                            v-validate="'min:1|space'"
                            label="orders.dropoff_comment"
                            :disabled="!innerDeliveryJob.canYouEditContactInfo"
                            name="dropoffComment"
                            counter="9999"
                            :error-messages="errors.collect('dropoffComment')"
                            :data-vv-as="$t('orders.dropoff_comment')")

</template>

<script>
import { mapGetters } from 'vuex'
import deliveryType from '@/store/type/deliveryType'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import DeliveriesApiClient from '@/api/RestApi/DeliveriesApiClient'
import baseFormType from '@/store/type/baseFormType'
import Notification from '@/services/Notification/Notification'
import ModalDialog from '@/components/dialog/ModalDialog'
import TextInput from '@/components/form/Inputs/TextInput'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import FormOneColRow from '@/components/form/FormOneColRow'
import AddressAutocompleteInput from '@/components/form/Inputs/google/AddressAutocompleteInput'
import DatePickerInput from '@/components/form/Inputs/DatePickerInput'

export default {
    components: {
        ModalDialog,
        TextInput,
        FormOneColRow,
        FormTwoColRow,
        AddressAutocompleteInput,
        DatePickerInput,
    },
    props: {
        model: {
            type: Object,
            default: null,
        },
        deliveryJob: {
            type: Object,
            default: null,
        },
        mapper: {
            type: String,
            required: true,
        },
        api: {
            type: String,
            required: true,
        },
        statusRef: Object,
    },
    data() {
        return {
            dialog: false,
            loading: false,
            innerModel: {},
            innerDeliveryJob: {},
        }
    },
    computed: {
        ...mapGetters({
            providerJobs: deliveryType.getters.PROVIDER_JOBS,
        }),
        changed() {
            return !!Object.values(this.veeFields).find(
                (field) => field.changed
            )
        },
        minDate() {
            return this.$date().format('YYYY-MM-DD')
        },
        isRestaurantManaged() {
            return (
                (isEmpty(this.deliveryJob) ||
                    this.deliveryJob.primaryMethod === 'restaurant_managed') &&
                this.model.isDeliveryProcessed
            )
        },
    },
    methods: {
        setDialog(value) {
            this.dialog = value
            if (this.dialog) {
                this.innerModel = cloneDeep(this.model)
                this.innerDeliveryJob = cloneDeep(this.deliveryJob)
            }
        },
        onApprove() {
            this.loading = true
            if (this.isRestaurantManaged) {
                this.$store
                    .dispatch(`baseForm/${baseFormType.actions.SAVE}`, {
                        model: this.innerModel,
                        api: this.api,
                        id: this.innerModel.id,
                        mapper: this.mapper,
                    })
                    .then(() => {
                        this.setDialog(false)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            } else {
                let changed =
                    !isEqual(this.innerDeliveryJob, this.deliveryJob) ||
                    !isEqual(this.innerModel, this.model)
                if (!changed) return this.setDialog(false)
                this.loading = true

                const promises = [
                    DeliveriesApiClient.updateDeliveryJob(
                        this.innerDeliveryJob
                    ),
                ]
                if (
                    this.innerModel.requiredDeliveryTime !==
                    this.model.requiredDeliveryTime
                ) {
                    promises.push(
                        this.$store.dispatch(
                            `baseForm/${baseFormType.actions.SAVE}`,
                            {
                                model: this.innerModel,
                                api: this.api,
                                id: this.innerModel.id,
                                mapper: this.mapper,
                            }
                        )
                    )
                }

                Promise.all(promises)
                    .then(async () => {
                        this.setDialog(false)
                        if (this.providerJobs?.length) {
                            // we have providerjobs, cancel them
                            for (let providerJob of this.providerJobs) {
                                if (this.statusRef) {
                                    await this.statusRef.cancelProviderJob(
                                        providerJob.id
                                    )
                                } else {
                                    await DeliveriesApiClient.cancelDeliveryProviderJob(
                                        providerJob.id
                                    ).then((res) => {
                                        if (res.success)
                                            this.$store.dispatch(
                                                deliveryType.actions
                                                    .CANCEL_PROVIDER_JOB,
                                                providerJob.id
                                            )
                                    })
                                }
                            }

                            Notification.warning(
                                this.$t(
                                    'notifications.edit_delivery_details.title'
                                ),
                                this.$t(
                                    'notifications.edit_delivery_details.message'
                                )
                            )
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        },
    },
}
</script>
