<template>
    <v-tab-item
        key="order_refund_item_tab"
        :transition="false"
        :reverse-transition="false"
        eager
    >
        <form-panel>
            <v-simple-table>
                <thead>
                    <tr>
                        <th>{{ $t('orders.item') }}</th>
                        <th>{{ $t('orders.unit_price') }}</th>
                        <th>{{ $t('orders.qty') }}</th>
                        <th>{{ $t('orders.total') }}</th>
                        <th>{{ $t('orders.remove_qty') }}</th>
                        <th>{{ $t('orders.refund_amount') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(meal, index) in order.meals"
                        :key="meal.id"
                        class="v-align-top"
                    >
                        <td class="py-2">
                            <div class="text-truncate">{{ meal.name }}</div>
                            <div
                                v-for="option in meal.children"
                                :key="option.id"
                                class="pl-3 text-body-2 g-73--text text-truncate option-line"
                            >
                                {{ option.name }}
                            </div>
                        </td>
                        <td class="py-2">
                            {{
                                meal.priceWithMealOptions
                                    | currency(order.currency, order.locale)
                            }}
                            <div
                                v-if="order.companyServiceFee && hasUnitPrices"
                            >
                                +
                                {{
                                    (itemUnitPrices[meal.id] -
                                        meal.priceWithMealOptions)
                                        | currency(order.currency, order.locale)
                                }}
                                {{ $t('labels.service_fee') }}
                            </div>
                        </td>
                        <td class="py-2">&times; {{ meal.quantity }}</td>
                        <td class="py-2">
                            {{
                                (meal.priceWithMealOptions * meal.quantity)
                                    | currency(order.currency, order.locale)
                            }}
                            <div
                                v-if="order.companyServiceFee && hasUnitPrices"
                            >
                                +
                                {{
                                    ((itemUnitPrices[meal.id] -
                                        meal.priceWithMealOptions) *
                                        meal.quantity)
                                        | currency(order.currency, order.locale)
                                }}
                                {{ $t('labels.service_fee') }}
                            </div>
                        </td>
                        <td class="py-1">
                            <v-text-field
                                v-if="hasValueItem(index)"
                                v-model="model.items[index].quantity"
                                :suffix="`/ ${
                                    meal.quantity - meal.refundedQuantity
                                }`"
                                :min="0"
                                :max="meal.quantity - meal.refundedQuantity"
                                :style="getInputStyle(index)"
                                class="meal-refund-input"
                                hide-details="auto"
                                type="number"
                            >
                            </v-text-field>
                        </td>
                        <td class="py-2 text-right">
                            {{ refundValue(meal) }}
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </form-panel>
    </v-tab-item>
</template>

<script>
import FormInput from '@/components/mixins/FormInput'
import FormPanel from '@/components/form/FormPanel'

export default {
    components: {
        FormPanel,
    },
    mixins: [FormInput],
    props: {
        order: {
            type: Object,
            default: () => ({ meals: [] }),
        },
        calculateResult: {
            type: Object,
            default: () => ({}),
        },
        itemUnitPrices: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        hasUnitPrices() {
            return Object.keys(this.itemUnitPrices).length > 0
        },
    },
    methods: {
        hasValueItem(index) {
            return (
                this.model?.items?.[index] &&
                'quantity' in this.model.items[index]
            )
        },
        refundValue(item) {
            let calcItem = null
            if (this.calculateResult?.items) {
                this.calculateResult.items.forEach((calc) => {
                    if (calc.item === item.id) {
                        calcItem = calc
                    }
                })
            }
            return calcItem
                ? this.$options.filters.currency(
                      calcItem.amount,
                      this.order.currency,
                      this.order.locale
                  )
                : '--'
        },
        getInputStyle(index) {
            const meal = this.order.meals[index]
            const len = `${meal.quantity - meal.refundedQuantity}`.length
            return {
                '--qty-width': `${len}em`,
            }
        },
    },
}
</script>

<style lang="scss">
.option-line {
    max-width: 160px;
}
.meal-refund-input {
    padding-top: 0;
    margin-top: 0;

    input {
        width: calc(var(--qty-width, 64px) + 1em);
    }
}
</style>
