<script>
import ModalPrintLabelDialog from '@/components/dialog/ModalPrintLabelDialog'
import RestApiCollection from '@/api/RestApiCollection'
import RestApiType from '@/api/RestApiType'
import printJS from 'print-js'

export default {
    components: { ModalPrintLabelDialog },
    props: {
        order: {
            type: Object,
            default: null,
        },
        classes: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            dialog: false,
        }
    },
    computed: {
        orderIdentifier() {
            return this.order.identifier
        },
    },
    methods: {
        showModal() {
            this.dialog = true
        },
        hideModal() {
            this.dialog = false
        },
        downloadList() {
            RestApiCollection.get(RestApiType.ORDERS)
                .downloadMealList(this.order.id)
                .then((response) => {
                    const blob = new Blob([response], {
                        type: 'application/pdf',
                    })
                    const blobUrl = URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = blobUrl
                    link.download = this.orderIdentifier
                    link.click()
                    URL.revokeObjectURL(link.href)
                })
                .catch(console.error)
        },
        printList() {
            RestApiCollection.get(RestApiType.ORDERS)
                .downloadMealList(this.order.id)
                .then((response) => {
                    const blob = new Blob([response], {
                        type: 'application/pdf',
                    })
                    const blobUrl = URL.createObjectURL(blob)
                    printJS(blobUrl)
                })
                .catch(console.error)
        },
    },
}
</script>

<template>
    <div>
        <div v-if="order" class="print-label">
            <v-btn
                :class="classes ? classes : ''"
                color="info"
                small
                @click="showModal"
            >
                {{ $t('orders.print_meal_list') }}
                <v-icon class="ml-1" small>print</v-icon>
            </v-btn>
        </div>

        <modal-print-label-dialog
            :value="dialog"
            :title="$t('texts.print_meal_list')"
            :download-text="$t('actions.download_meal_list')"
            :print-text="$t('actions.print_meal_list')"
            @close="hideModal"
            @downloadLabels="downloadList"
            @printLabels="printList"
        >
            <div>{{ $t('labels.order') }} #{{ orderIdentifier }}</div>
        </modal-print-label-dialog>
    </div>
</template>
