import ResponseHandler from '@/api/response/ResponseHandler'
import AbstractRestApi from '@/api/RestApi/AbstractRestApi'
import apiClient from '@/api/ApiClient'

class PublicApiClient extends AbstractRestApi {
    getBaseUrl() {
        return '/public'
    }

    getDateTime() {
        return ResponseHandler.handle(
            apiClient.get(this.getBaseUrl() + '/datetime')
        )
    }
}

export default new PublicApiClient()
