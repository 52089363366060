<template>
    <modal-dialog
        :value="modal"
        scrollable
        cancel-button
        :max-width="800"
        :ok-disabled="!isCompleted"
        :ok-text="$t('orders.refund_confirm') + ' ' + refund"
        :ok-loading="loading"
        @input="$emit('update:modal', false)"
        @ok="$emit('finalize')"
    >
        <div v-if="isItemRefund">
            <h3 class="text-h6 font-weight-bold mb-4">
                {{ $t('labels.order_refund_item') }}
            </h3>
            <v-simple-table>
                <thead>
                    <tr>
                        <th>{{ $t('orders.item') }}</th>
                        <th>{{ $t('orders.qty') }}</th>
                        <th>{{ $t('orders.refund_amount') }}</th>
                        <th>{{ $t('orders.refund_item_reason') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="item in items"
                        :key="item.item"
                        class="v-align-top"
                    >
                        <td class="py-2">
                            <div class="text-truncate">{{ item.name }}</div>
                            <div
                                v-for="option in item.children"
                                :key="option.id"
                                class="pl-3 text-body-2 g-73--text text-truncate option-line"
                            >
                                {{ option.name }}
                            </div>
                        </td>
                        <td class="py-2">&times; {{ item.quantity }}</td>
                        <td class="py-2">
                            {{
                                item.amount
                                    | currency(order.currency, order.locale)
                            }}
                        </td>
                        <td class="py-1">
                            <select-input
                                v-model="modelItems[item.item].selectReason"
                                :items="itemReasons"
                                hide-details="auto"
                                @input="updateItemReason(item.item)"
                            ></select-input>
                            <text-input
                                v-show="
                                    modelItems[item.item].selectReason ===
                                    'other'
                                "
                                v-model="modelItems[item.item].reason"
                                hide-details="auto"
                            ></text-input>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </div>

        <div v-else>
            <h3 class="text-h6 font-weight-bold mb-4">
                {{ $t('labels.order_refund_partial') }}
            </h3>
            <v-row>
                <v-col md="8" class="py-0">
                    <select-input
                        v-model="model.selectReason"
                        :items="orderReasons"
                        label="orders.refund_reason"
                        hide-details="auto"
                        @input="updateReason"
                    ></select-input>
                    <text-input
                        v-show="model.selectReason === 'other'"
                        v-model="model.reason"
                        hide-details="auto"
                    ></text-input>
                </v-col>
            </v-row>
        </div>

        <v-row>
            <v-col md="8" class="py-0 mt-4">
                <select-input
                    v-model="model.refundOnBehalf"
                    label="orders.on_behalf_of"
                    :items="behalfOptions"
                ></select-input>
            </v-col>
        </v-row>

        <div class="rounded-lg blue-1 pa-4">
            <h3 class="text-h6 font-weight-bold mb-4">
                {{ $t('orders.to_refund') }}
            </h3>
            <v-row
                v-if="calculateResult.user_amount > 0"
                justify="space-between"
            >
                <v-col cols="auto" class="py-0 pr-0">
                    {{
                        $t('orders.from_payment', {
                            method: $t(
                                `enums.payment_methods.${order.paymentMethod}`
                            ),
                        })
                    }}
                </v-col>
                <v-col cols="auto" class="py-0 pl-0 text-right">
                    {{
                        (calculateResult.user_amount || 0)
                            | currency(order.currency, order.locale)
                    }}
                </v-col>
            </v-row>
            <v-row
                v-if="calculateResult.company_amount > 0"
                justify="space-between"
            >
                <v-col cols="auto" class="py-0 pr-0">
                    {{ $t('orders.from_allowance') }}
                </v-col>
                <v-col cols="auto" class="py-0 pl-0 text-right">
                    {{
                        (calculateResult.company_amount || 0)
                            | currency(order.currency, order.locale)
                    }}
                </v-col>
            </v-row>
            <v-row justify="space-between">
                <v-col cols="auto" class="py-0 pr-0">
                    {{ $t('orders.total') }}
                </v-col>
                <v-col cols="auto" class="py-0 pl-0 text-right">
                    {{ refundSum | currency(order.currency, order.locale) }}
                </v-col>
            </v-row>
        </div>
    </modal-dialog>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import FormInput from '@/components/mixins/FormInput'
import ModalDialog from '@/components/dialog/ModalDialog'
import TextInput from '@/components/form/Inputs/TextInput'
import SelectInput from '@/components/form/Inputs/SelectInput'
import { itemReasons, orderReasons } from './helpers'

export default {
    components: {
        ModalDialog,
        TextInput,
        SelectInput,
    },
    mixins: [FormInput],
    props: {
        order: {
            type: Object,
            default: () => ({ meals: [] }),
        },
        calculateResult: {
            type: Object,
            default: () => ({}),
        },
        refundSum: {
            type: Number,
            default: 0,
        },
        modal: Boolean,
        loading: Boolean,
    },
    computed: {
        isItemRefund() {
            return 'items' in this.model
        },
        isCompleted() {
            let completed = true
            if (this.isItemRefund) {
                completed =
                    completed &&
                    this.model.items.every((item) => {
                        return (
                            item.quantity == 0 ||
                            (typeof item.reason === 'string' &&
                                item.reason.length > 0)
                        )
                    })
            } else {
                completed =
                    completed &&
                    typeof this.model.reason === 'string' &&
                    this.model.reason.length > 0
            }
            return completed
        },
        refund() {
            return this.$options.filters.currency(
                this.refundSum,
                this.order.currency,
                this.order.locale
            )
        },
        itemReasons() {
            return itemReasons.map((value) => {
                return {
                    title: this.$t(`orders.refund_reasons.${value}`),
                    value,
                }
            })
        },
        orderReasons() {
            return orderReasons.map((value) => {
                return {
                    title: this.$t(`orders.refund_reasons.${value}`),
                    value,
                }
            })
        },
        behalfOptions() {
            return ['ordit', 'restaurant', 'logistics'].map((value) => {
                return {
                    title: this.$t(`enums.refund_on_behalf.${value}`),
                    value,
                }
            })
        },
        items() {
            const items = cloneDeep(this.calculateResult.items) || []
            items.forEach((el) => {
                const meal = this.order.meals.find(
                    (meal) => meal.id === el.item
                )
                el.name = meal.name
                el.children = cloneDeep(meal.children)
            })
            return items
        },
        modelItems() {
            const result = {}
            this.model.items.forEach((el) => {
                result[el.item] = el
            })
            return result
        },
    },
    methods: {
        updateReason() {
            if (this.model.selectReason !== 'other') {
                this.model.reason = this.$t(
                    `orders.refund_reasons.${this.model.selectReason}`
                )
            } else {
                this.model.reason = ''
            }
        },
        updateItemReason(item) {
            const value = this.model.items.find((el) => el.item === item)
            if (value.selectReason !== 'other') {
                value.reason = this.$t(
                    `orders.refund_reasons.${value.selectReason}`
                )
            } else {
                value.reason = ''
            }
        },
    },
}
</script>
