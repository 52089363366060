<template lang="pug">
.order-admin-reorder
    v-tooltip(bottom)
        template(v-slot:activator="{ on }")
            v-btn(
                v-bind="$attrs"
                v-on="on"
                @click="dialog = true" text)
                slot
                    span.text-subtitle-2 {{ $t('labels.reorder') }}
                    v-icon(dense) replay
        span(v-if="tooltip") {{ tooltip }}
    modal-dialog.admin-reorder-modal(
        v-model="dialog"
        :show-overlay="loading"
        :title="$t('labels.reorder_modal_title', { 'identifier': orderIdentifier })"
        :ok-text="$t('actions.reorder')"
        :ok-disabled="!reason"
        @cancel="dialog = false"
        @ok="reorder"
        cancel-button)
            text-input(
                v-model="reason"
                v-validate.immediate="'required'"
                label="labels.reorder_reason"
                name="reorder_reason"
                :error-messages="errors.collect('reorder_reason')"
                :data-vv-as="$t('labels.reorder_reason')"
                )

</template>

<script>
import RestApiCollection from '@/api/RestApiCollection'
import ApiClientType from '@/api/RestApiType'
import routeType from '@/router/routeType'
import ModalDialog from '@/components/dialog/ModalDialog'
import TextInput from '@/components/form/Inputs/TextInput'

export default {
    components: {
        ModalDialog,
        TextInput,
    },
    props: {
        order: {
            type: Object,
            default: null,
        },
        tooltip: {
            type: String,
            default: null,
        },
    },
    inject: {
        $validator: 'formValidator',
    },
    data() {
        return {
            dialog: false,
            loading: false,
            reason: '',
        }
    },
    computed: {
        orderId() {
            return this.order?.id
        },
        orderIdentifier() {
            return this.order?.identifier
        },
    },
    methods: {
        reorder() {
            this.loading = true
            RestApiCollection.get(ApiClientType.ORDERS)
                .reOrder(this.orderId, this.reason)
                .then((response) => {
                    const id = response['@id'].split('/').pop()
                    this.dialog = false
                    this.$router.push({
                        name: routeType.ORDER_EDIT,
                        params: { id },
                    })
                })
                .finally(() => {
                    this.reason = ''
                    this.loading = false
                })
        },
    },
}
</script>
