<template>
    <v-card class="border-g-e8" flat>
        <v-card-text class="py-2 pb-5">
            <h3 class="text-h6 font-weight-bold mb-4">
                {{ $t('orders.summary') }}
            </h3>
            <v-row justify="space-between">
                <v-col cols="auto" class="py-0 pr-0">{{
                    $t('orders.subtotal')
                }}</v-col>
                <v-col cols="auto" class="py-0 pl-0 text-right">
                    {{
                        order.priceSumItems
                            | currency(order.currency, order.locale)
                    }}
                </v-col>
            </v-row>
            <v-row justify="space-between">
                <v-col cols="auto" class="py-0 pr-0">{{
                    $t('orders.delivery_fee')
                }}</v-col>
                <v-col cols="auto" class="py-0 pl-0 text-right">
                    {{
                        order.priceDelivery
                            | currency(order.currency, order.locale)
                    }}
                </v-col>
            </v-row>
            <v-row v-if="order.companyServiceFee" justify="space-between">
                <v-col cols="auto" class="py-0 pr-0">{{
                    $t('labels.service_fee')
                }}</v-col>
                <v-col cols="auto" class="py-0 pl-0 text-right">
                    {{
                        order.companyServiceFee
                            | currency(order.currency, order.locale)
                    }}
                </v-col>
            </v-row>

            <v-divider class="my-3"></v-divider>

            <v-row v-if="hasUserPayment" justify="space-between">
                <v-col cols="auto" class="py-0 pr-0">
                    {{
                        $t('orders.paid_by_method', {
                            method: $t(
                                `enums.payment_methods.${order.paymentMethod}`
                            ),
                        })
                    }}
                </v-col>
                <v-col cols="auto" class="py-0 pl-0 text-right">
                    {{
                        order.priceSumUser
                            | currency(order.currency, order.locale)
                    }}
                </v-col>
            </v-row>
            <v-row
                v-if="hasUserPayment"
                justify="space-between"
                class="text-body-2 g-73--text mt-0"
            >
                <v-col cols="auto" class="pl-4 py-0 pr-0">
                    {{ $t('orders.available_for_refund') }}
                </v-col>
                <v-col cols="auto" class="py-0 pl-0 text-right">
                    {{
                        (order.priceSumUser - order.refundedUserPrice)
                            | currency(order.currency, order.locale)
                    }}
                </v-col>
            </v-row>

            <v-row v-if="hasCompanyPayment" justify="space-between">
                <v-col cols="auto" class="py-0 pr-0">
                    {{ $t('labels.paid_allowance') }}
                </v-col>
                <v-col cols="auto" class="py-0 pl-0 text-right">
                    {{
                        order.priceSumFirm
                            | currency(order.currency, order.locale)
                    }}
                </v-col>
            </v-row>
            <v-row
                v-if="hasCompanyPayment"
                justify="space-between"
                class="text-body-2 g-73--text mt-0"
            >
                <v-col cols="auto" class="pl-4 py-0 pr-0">
                    {{ $t('orders.available_for_refund') }}
                </v-col>
                <v-col cols="auto" class="py-0 pl-0 text-right">
                    {{
                        (order.priceSumFirm - order.refundedCompanyPrice)
                            | currency(order.currency, order.locale)
                    }}
                </v-col>
            </v-row>

            <v-row justify="space-between">
                <v-col cols="auto" class="py-0 pr-0">
                    {{ $t('orders.total') }}
                </v-col>
                <v-col cols="auto" class="py-0 pl-0 text-right">
                    {{
                        order.priceSum | currency(order.currency, order.locale)
                    }}
                </v-col>
            </v-row>
            <v-row justify="space-between" class="text-body-2 g-73--text mt-0">
                <v-col cols="auto" class="pl-4 py-0 pr-0">
                    {{ $t('orders.available_for_refund') }}
                </v-col>
                <v-col cols="auto" class="py-0 pl-0 text-right">
                    {{ refundableSum | currency(order.currency, order.locale) }}
                </v-col>
            </v-row>

            <v-divider class="my-3"></v-divider>

            <h3 class="text-h6 font-weight-bold mb-4">
                {{ $t('orders.to_refund') }}
            </h3>
            <v-row
                v-if="calculateResult.user_amount > 0"
                justify="space-between"
            >
                <v-col cols="auto" class="py-0 pr-0">
                    {{
                        $t('orders.from_payment', {
                            method: $t(
                                `enums.payment_methods.${order.paymentMethod}`
                            ),
                        })
                    }}
                </v-col>
                <v-col cols="auto" class="py-0 pl-0 text-right">
                    {{
                        (calculateResult.user_amount || 0)
                            | currency(order.currency, order.locale)
                    }}
                </v-col>
            </v-row>
            <v-row
                v-if="calculateResult.company_amount > 0"
                justify="space-between"
            >
                <v-col cols="auto" class="py-0 pr-0">
                    {{ $t('orders.from_allowance') }}
                </v-col>
                <v-col cols="auto" class="py-0 pl-0 text-right">
                    {{
                        (calculateResult.company_amount || 0)
                            | currency(order.currency, order.locale)
                    }}
                </v-col>
            </v-row>
            <v-row justify="space-between">
                <v-col cols="auto" class="py-0 pr-0">
                    {{ $t('orders.total') }}
                </v-col>
                <v-col cols="auto" class="py-0 pl-0 text-right">
                    {{ refundSum | currency(order.currency, order.locale) }}
                </v-col>
            </v-row>
            <div v-show="calculateError" class="my-4 error--text">
                {{ calculateError }}
            </div>
            <div class="mt-4"><slot></slot></div>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        order: {
            type: Object,
            default: () => ({}),
        },
        calculateResult: {
            type: Object,
            default: () => ({}),
        },
        refundSum: {
            type: Number,
            default: 0,
        },
        refundableSum: {
            type: [Number, String],
            default: 0,
        },
        calculateError: {
            type: String,
            default: '',
        },
    },

    computed: {
        hasUserPayment() {
            return parseFloat(this.order?.priceSumUser) > 0
        },
        hasCompanyPayment() {
            return parseFloat(this.order?.priceSumFirm) > 0
        },
    },
}
</script>
