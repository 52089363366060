<template>
    <v-tab-item
        key="refund_tab"
        :transition="false"
        :reverse-transition="false"
    >
        <overlay :show="busy">
            <refund-history :order="order"></refund-history>
            <v-row>
                <v-col cols="auto" class="order-refund-tabs-col">
                    <form-tabs v-model="activeTab" class="pb-lg-10">
                        <form-tab
                            v-for="tab in tabs"
                            :key="tab.name"
                            :tab-errors="tabErrors"
                            :name="tab.name"
                            :title="tab.title"
                            :disabled="tab.disabled ? true : false"
                        ></form-tab>

                        <v-tabs-items v-model="activeTab">
                            <item-refund
                                v-model="payload"
                                :order="order"
                                :calculate-result="calculateResult"
                                :item-unit-prices="itemUnitPrices"
                            ></item-refund>
                            <partial-refund
                                v-model="payload"
                                :order="order"
                                :refundable-sum="refundableSum"
                            ></partial-refund>
                        </v-tabs-items>
                    </form-tabs>
                </v-col>

                <v-col cols="auto" class="order-refund-summary-col">
                    <refund-summary
                        :order="order"
                        :calculate-result="calculateResult"
                        :refund-sum="refundSum"
                        :refundable-sum="refundableSum"
                        :calculate-error="calculateError"
                    >
                        <v-btn
                            block
                            color="primary"
                            :disabled="refundSum === 0"
                            @click="modal = true"
                        >
                            {{ $t('labels.order_refund') }}
                            {{
                                refundSum
                                    | currency(order.currency, order.locale)
                            }}
                        </v-btn>
                    </refund-summary>
                </v-col>
            </v-row>
        </overlay>
        <finalize-modal
            v-model="payload"
            :order="order"
            :calculate-result="calculateResult"
            :refund-sum="refundSum"
            :loading="busy"
            :modal.sync="modal"
            @finalize="doRefund"
        ></finalize-modal>
    </v-tab-item>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import FormTabMixin from '@/components/mixins/FormTabMixin'
import OrdersApiClient from '@/api/RestApi/OrdersApiClient'
import {
    ItemRefundPayload,
    AmountRefundPayload,
    filterPayload,
    filterCalculateRelevant,
    hasValueToCalculate,
    clampItemQuantities,
} from './refund/helpers'
import Notification from '@/services/Notification/Notification'

import FormTabs from '@/components/form/FormTabs'
import FormTab from '@/components/form/FormTab'
import Overlay from '@/components/overlay/Overlay'

import RefundHistory from './refund/RefundHistory'
import ItemRefund from './refund/ItemRefund'
import PartialRefund from './refund/PartialRefund'
import RefundSummary from './refund/RefundSummary'
import FinalizeModal from './refund/FinalizeModal'

let IRPayload = cloneDeep(ItemRefundPayload),
    ARPayload = cloneDeep(AmountRefundPayload)

export default {
    components: {
        FormTabs,
        FormTab,
        Overlay,
        RefundHistory,
        ItemRefund,
        PartialRefund,
        RefundSummary,
        FinalizeModal,
    },

    mixins: [FormTabMixin],

    inject: {
        $validator: 'formValidator',
    },

    props: {
        tabActive: Boolean,
        order: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            payload: {},
            calculateResult: {},
            lastPayload: '',
            busy: false,
            modal: false,
            calculateError: '',
            itemUnitPrices: {},
        }
    },

    computed: {
        tabs() {
            return [
                {
                    name: 'itemRefund',
                    title: this.$t('labels.order_refund_item'),
                },
                {
                    name: 'partialRefund',
                    title: this.$t('labels.order_refund_partial'),
                },
            ]
        },
        activeTab: {
            get() {
                return parseInt(this.$route.query.subTab) || 0
            },
            set(val) {
                this.$router.replace({
                    query: { subTab: val, tab: this.$route.query.tab },
                })
            },
        },
        tabErrors() {
            const tabErrors = {}
            Object.keys(this.tabErrorsContainer).forEach((tabName) => {
                Object.keys(this.tabErrorsContainer[tabName]).forEach(
                    (propertyName) => {
                        if (!tabErrors[tabName]) {
                            tabErrors[tabName] = 0
                        }
                        if (
                            this.tabErrorsContainer[tabName][propertyName] > 0
                        ) {
                            tabErrors[tabName]++
                        }
                    }
                )
            })
            return tabErrors
        },
        refundSum() {
            return (
                (this.calculateResult.company_amount || 0) +
                (this.calculateResult.user_amount || 0)
            )
        },
        refundableSum() {
            return (
                Math.round(
                    (this.order?.priceSum - this.order?.refundedPrice) * 100
                ) / 100 || 0
            )
        },
    },

    watch: {
        activeTab() {
            this.checkPayload()
        },
        order() {
            this.checkUnitPrices()
            this.checkPayload()
        },
        tabActive(val) {
            if (val) {
                this.reinit()
                this.checkUnitPrices()
            }
        },
        payload: {
            handler(val) {
                if (this.tabActive && !isEmpty(val) && this.order?.id) {
                    this.calculate()
                }
            },
            deep: true,
        },
    },

    methods: {
        getTabError(val) {
            this.tabErrorsContainer = {
                ...this.tabErrorsContainer,
                ...val,
            }

            let subTabErrors = {}

            Object.keys(this.tabErrorsContainer).forEach((tabName) => {
                subTabErrors = {
                    ...subTabErrors,
                    ...this.tabErrorsContainer[tabName],
                }
            })

            this.$emit('tabError', {
                refund: subTabErrors,
            })
        },
        calculate() {
            if (
                clampItemQuantities(this.payload, this.order) ||
                !hasValueToCalculate(this.payload)
            )
                return

            const str = JSON.stringify(filterCalculateRelevant(this.payload))
            if (str === this.lastPayload) return

            this.busy = true
            this.lastPayload = str

            OrdersApiClient.refundOrderCalculate(
                this.order.id,
                filterPayload(this.payload)
            )
                .then((res) => {
                    this.calculateError = ''
                    this.calculateResult = res
                    if (!this.itemUnitPrices) {
                        this.itemUnitPrices = cloneDeep(res.itemUnitPrices)
                    }
                })
                .catch((err) => {
                    if (err?.response?.data?.errors) {
                        this.calculateError = err.response.data.errors.join(
                            '; '
                        )
                        this.calculateResult = {}
                    }
                })
                .finally(() => {
                    this.busy = false
                })
        },
        checkPayload() {
            if (this.activeTab === 0) {
                if (isEmpty(this.payload) || !('items' in this.payload)) {
                    this.$set(this, 'payload', IRPayload)
                }

                if (
                    this.order?.meals?.length > 0 &&
                    this.payload?.items?.length !== this.order?.meals?.length
                ) {
                    this.payload.items = []
                    this.order.meals.forEach((meal) => {
                        this.payload.items.push({
                            item: meal.id,
                            quantity: 0,
                            reason: '',
                        })
                    })
                }
            } else {
                if (isEmpty(this.payload) || !('amount' in this.payload)) {
                    this.$set(this, 'payload', ARPayload)
                }
            }
        },
        reinit() {
            IRPayload = cloneDeep(ItemRefundPayload)
            ARPayload = cloneDeep(AmountRefundPayload)
            this.$set(this, 'payload', IRPayload)
            this.checkPayload()
            this.calculateResult = {}
            this.calculateError = ''
        },
        checkUnitPrices() {
            if (
                this.tabActive &&
                this.order?.id &&
                isEmpty(this.itemUnitPrices)
            ) {
                this.busy = true

                OrdersApiClient.refundOrderCalculate(
                    this.order.id,
                    filterPayload(AmountRefundPayload)
                )
                    .then((res) => {
                        this.itemUnitPrices = res.itemUnitPrices
                    })
                    .finally(() => {
                        this.busy = false
                    })
            }
        },
        doRefund() {
            this.busy = true

            OrdersApiClient.refundOrder(
                this.order.id,
                filterPayload(this.payload)
            )
                .then(() => {
                    Notification.success(
                        this.$t('notifications.refund.success')
                    )
                    this.$emit('refund')
                    this.reinit()
                    this.modal = false
                })
                .catch((err) => {
                    let message = err.response?.data?.errors?.join(';')
                    Notification.error(
                        this.$t('notifications.refund.error', {
                            message,
                        })
                    )
                })
                .finally(() => {
                    this.busy = false
                })
        },
    },
}
</script>

<style lang="scss">
.order-refund-tabs-col {
    width: 100%;
}
.order-refund-summary-col {
    width: 400px;
}

@media (min-width: #{map-get($grid-breakpoints, 'lg')}) {
    $width: 270px;

    .order-refund-tabs-col {
        width: calc(100% - #{$width});
    }
    .order-refund-summary-col {
        width: $width;
    }
}

@media (min-width: 1360px) {
    $width: 320px;

    .order-refund-tabs-col {
        width: calc(100% - #{$width});
    }
    .order-refund-summary-col {
        width: $width;
    }
}

@media (min-width: #{map-get($grid-breakpoints, 'xl')}) {
    $width: 30%;

    .order-refund-tabs-col {
        width: calc(100% - #{$width});
    }
    .order-refund-summary-col {
        width: $width;
    }
}
</style>
