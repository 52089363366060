<template>
    <v-tab-item
        key="order_refund_partial_tab"
        :transition="false"
        :reverse-transition="false"
        eager
    >
        <form-panel>
            <v-row>
                <v-col md="8">
                    <v-text-field
                        v-model="model.amount"
                        type="number"
                        :label="$t('orders.partial_refund')"
                        :suffix="currencyCode"
                    >
                        <template v-slot:append>
                            <v-btn
                                text
                                color="primary"
                                class="mb-1"
                                @click="fullAmount"
                            >
                                {{ $t('orders.refund_set_full') }}
                            </v-btn>
                        </template>
                    </v-text-field>
                    <p>
                        {{
                            $t('orders.partial_available', { sum: refundable })
                        }}
                    </p>
                </v-col>
            </v-row>
        </form-panel>
    </v-tab-item>
</template>

<script>
import FormInput from '@/components/mixins/FormInput'
import FormPanel from '@/components/form/FormPanel'

export default {
    components: {
        FormPanel,
    },
    mixins: [FormInput],
    props: {
        order: {
            type: Object,
            default: () => ({ meals: [] }),
        },
        calculateResult: {
            type: Object,
            default: () => ({}),
        },
        refundableSum: {
            type: [Number, String],
            default: 0,
        },
    },
    computed: {
        currencyCode() {
            return this.order?.currency
        },
        refundable() {
            return this.$options.filters.currency(
                this.refundableSum,
                this.order.currency,
                this.order.locale
            )
        },
    },
    methods: {
        fullAmount() {
            this.model.amount = parseFloat(this.refundableSum)
        },
    },
}
</script>
