<script>
import EditButton from '@/components/table/buttons/EditButton'
import Overlay from '@/components/overlay/Overlay'

export default {
    components: { EditButton, Overlay },
    props: {
        // eslint-disable-next-line vue/require-prop-types
        value: {
            required: true,
        },
        editClass: {
            type: String,
            default: '',
        },
        progress: Boolean,
    },
    data() {
        return {
            editing: false,
            innerValue: undefined,
        }
    },
    computed: {
        cclass() {
            const style = {}
            if (this.editing) {
                style[this.editClass] = true
            }
            return style
        },
        showEdit() {
            return this.editing || this.progress
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                this.innerValue = value
            },
        },
    },
    methods: {
        onSave() {
            if (this.innerValue !== this.value) {
                this.$emit('save', this.innerValue)
            }
            this.editing = false
        },
        setter(value) {
            this.innerValue = value
        },
    },
}
</script>

<template>
    <overlay class="d-inline-block" :class="cclass" :show="progress">
        <div class="d-inline-flex justify-space-between w-100">
            <slot v-if="!showEdit" :innerValue="innerValue"></slot>
            <edit-button
                v-if="!showEdit"
                @execute="editing = true"
            ></edit-button>
            <slot
                v-if="showEdit"
                :innerValue="innerValue"
                :setter="setter"
                name="input"
            ></slot>
            <div
                v-if="showEdit"
                class="d-inline-flex justify-space-end flex-nowrap"
            >
                <edit-button
                    :tooltip="$t('actions.save')"
                    class="mt-1"
                    icon="check_circle"
                    @execute="onSave"
                ></edit-button>
                <edit-button
                    :tooltip="$t('actions.cancel')"
                    class="mt-1"
                    icon="cancel"
                    @execute="editing = false"
                ></edit-button>
            </div>
        </div>
    </overlay>
</template>
