<template>
    <div>
        <order-form v-if="isSuperAdmin" :entity-id="entityId"></order-form>
        <company-order-form
            v-else-if="isCompanyAdmin"
            :entity-id="entityId"
        ></company-order-form>
    </div>
</template>

<script>
import OrderForm from '@/components/pages/orders/Form'
import CompanyOrderForm from '@/components/pages/orders/CompanyForm'
import dirtyForm from '@/components/mixins/dirtyForm'
import AuthMixin from '@/components/mixins/AuthMixin'

export default {
    components: {
        OrderForm,
        CompanyOrderForm,
    },
    mixins: [dirtyForm, AuthMixin],

    data() {
        return {
            entityId: null,
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(route) {
                this.entityId = route.params.id
            },
        },
    },
}
</script>
